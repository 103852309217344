import React from "react";
import { Layout } from "../components/Layout";
import { PromoWidget } from "../components/PromoWidget";
import { IndexSidebar } from "../components/sidebars/IndexSidebar";
import { IndexContent } from "../components/content/IndexContent";
import { Meta } from "../components/Meta";
import { GET_INDEX_PAGE } from "../mysql/queries/index-page";

export const getStaticProps = async (context) => {
  let indexResult = {};
  try {
    indexResult = await GET_INDEX_PAGE();
  } catch (ex) {
    console.error("failed to execute index query", ex);
  }
  return {
    props: {
      data: indexResult,
    },
  };
};

const Home = ({ data }) => {
  return (
    <>
      <Layout data={data}>
        <Meta
          title="RadCade | Instantly play your favorite free online games"
          description="Our collection of free online games will keep you entertained for days! huge selection of online games for all ages. 100% Free"
          url={data.settings[0].siteurl}
          type="index" />
        <div>
          <div className="responsive">
            <PromoWidget data={data} />
          </div>
        </div>
        <div className="responsive flex-col md:flex-row">
          <div className="flex-grow md:w-4/5 lg:w-4/5">
            <IndexContent data={data} />
          </div>
          <div className="w-sidebar flex-shrink-0 md:ml-5 lg:ml-5">
            <IndexSidebar data={data} />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Home;
