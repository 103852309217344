import React from "react";
import Link from "next/link";

export const IndexSidebar = ({ data }) => {
  return <>
    <div>
      <div className="section-title">Categories</div>
      <div>
        {data.categories.map((node) => {
          return (
            <Link
              href={node.uri}
              key={node.uri}
              className="text-sm hover:text-primary block py-0.5"
            >

              {node.name}

            </Link>
          );
        })}
      </div>
    </div>
    <div>
      <div className="section-title">NEW GAMES</div>
      <div>
        {data.posts.map((node, index) => {
          if (index > 10) {
            return null
          }
          return (
            <Link
              href={node.uri}
              key={node.uri}
              className="text-sm hover:text-primary block py-0.5"
            >

              {node.title}

            </Link>
          );
        })}
      </div>
    </div>
  </>;
};
