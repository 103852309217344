import React from "react";
import useAdsByGoogle from "./useAdsByGoogle"

export default function CategoryBoxAd() {
  useAdsByGoogle();
  // Category Box
  return (
    <ins className="adsbygoogle"
      style={{ display: 'inline-block', width: '400px', height: '165px' }}
      data-ad-client="ca-pub-4646414058040259"
      data-ad-slot="7075073493"
    />
  );
}
