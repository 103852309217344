import React from "react";
import { ThumbPostPreview } from "../ThumbPostPreview";
import { PostPreview } from "../PostPreview";
import Link from "next/link";
import FrontPageContentBanner from '../ads/FrontPageContentBanner';
import CategoryBoxAd from "../ads/CategoryBox";

export const IndexContent = ({ data }) => {
  return <>
    <div>
      <div className="section-title">PROMOTED GAMES</div>
      <div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4">
          {data.posts.map((node, index) => {
            if (
              !node?.images?.find(
                (size) => 150 === size.width
              ) ||
              index > 10 ||
              index < 1
            ) {
              return null;
            }

            return <ThumbPostPreview key={index} data={node} />;
          })}
        </div>
      </div>
    </div>
    <div>
      <div className="section-title">
        <h1>WEB GAMES & INDIE GAME NEWS!</h1>
      </div>
      <div>
        <span itemProp="text">
          Hey there, rad folks! Welcome to RadCade.com, the coolest indie gaming website around.
          We&#39;ve got all the best games, and we&#39;re always adding new ones.
          Come on in and check it out, it's like a totally excellent house party for gamers. We've got all the arcade classics, plus some seriously bodacious new releases. Don&#39;t be a party pooper, come hang out with us and score some epic high scores. You won&#39;t be disappointed, trust me. See you at the virtual arcade!
        </span>
      </div>
      <div
        className="block justify-center items-center mt-6 h-auto"
        style={{ Width: 728 }}>
        <FrontPageContentBanner />
      </div>
    </div>
    {data.categories.map((node, index) => {
      if (!node.posts || node.posts.length < 2) return null;
      return (
        <div key={index}>
          <div className="section-title items-center flex justify-between">
            <Link
              href={node.uri}
              className="hover:text-primary transition-colors"
              title={node.name}
              itemProp="url">

              {node.name}

            </Link>

            <Link
              href={node.uri}
              className="group flex items-center rounded-sm overflow-hidden"
              title={node.name}
              itemProp="url">

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="fill-current w-3 h-3 bg-primary p-1 h-5 w-5 bg-block group-hover:bg-primary group-hover:bg-opacity-80"
              >
                <path d="M256 0C114.833 0 0 114.833 0f 256s114.833 256 256 256 256-114.853 256-256S397.167 0 256 0zm0 472.341c-119.275 0-216.341-97.046-216.341-216.341S136.725 39.659 256 39.659 472.341 136.705 472.341 256 375.295 472.341 256 472.341z" />
                <path d="M355.148 234.386H275.83v-79.318c0-10.946-8.864-19.83-19.83-19.83s-19.83 8.884-19.83 19.83v79.318h-79.318c-10.966 0-19.83 8.884-19.83 19.83s8.864 19.83 19.83 19.83h79.318v79.318c0 10.946 8.864 19.83 19.83 19.83s19.83-8.884 19.83-19.83v-79.318h79.318c10.966 0 19.83-8.884 19.83-19.83s-8.864-19.83-19.83-19.83z" />
              </svg>
              <span className="text-xs bg-primary font-bold py-0.5 px-1 hidden group-hover:block">
                More Games
              </span>

            </Link>
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-4">
            {node.posts.map((node, index) => {
              return <PostPreview data={node} key={index} />;
            })}
            <div className="flex-basis-0 flex mb-4 md:mb-0 block flex-col md:flex-row items-center sm:items-start sm:flex">
              <div style={{ width: "417px", height: "173px", display: "block" }}>
                <CategoryBoxAd />
              </div>
            </div>
          </div>
        </div>
      );
    })}
    <div>
      <div className="section-title">LATEST GAMES</div>
      <div className="flex flex-wrap">
        {data.posts.map((node) => {
          return (
            (
              <Link
                href={node.uri}
                key={node.uri}
                title={node.title}
                itemProp="url"
                className="link text-xs font-bold w-1/2 sm:w-1/3 md:w-1/4 py-1 whitespace-nowrap overflow-hidden overflow-ellipsis pr-3">
                {node.title}
              </Link>
            )
          );
        })}
      </div>
    </div>
  </>;
};
