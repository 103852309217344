import React, { useRef } from "react";
import Link from "next/link";
import classNames from "classnames";
import useHover from "@react-hook/hover";
import { noImageUrl } from "../config";

export const ThumbPostPreviewSimple = ({ data }) => {
  if (!data) {
    return null;
  }

  const target = useRef(null);
  const isHovering = useHover(target);
  const image =
    data.images?.find(
      (size) => 150 === size.width || 150 === size.height
    ) ||
    data.featured_image_url ||
    noImageUrl;

  if (!image) {
    return null;
  }

  return (
    <div ref={target} className="overflow-hidden relative">
      <Link
        href={data.uri}
        className="flex overflow-hidden"
        itemProp="url"
        title={data.title}
      >
        <img
          className={classNames("w-full h-full object-cover", {
            "filter brightness-50 scale-150 transform transition-all":
              isHovering,
          })}
          width="150"
          height="150"
          src={image?.sourceUrl ? image?.sourceUrl : image}
          alt={data.title}
        />

      </Link>
      <div
        className={classNames(
          "absolute bottom-0 left-0 w-full transition-all",
          {
            hidden: !isHovering,
          }
        )}
      >
        <Link
          href={data.uri}
          className="flex justify-center items-center bg-block text-sm font-bold mt-1 text-center p-2"
          itemProp="url"
          title={data.title}
        >

          <svg
            className="w-4 h-4 mr-2 fill-current text-primary"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 53.626 53.626"
          >
            <path d="M48.831 15.334c-7.083-11.637-17.753-3.541-17.753-3.541-.692.523-1.968.953-2.835.955l-2.858.002c-.867.001-2.143-.429-2.834-.952 0 0-10.671-8.098-17.755 3.539C-2.286 26.97.568 39.639.568 39.639c.5 3.102 2.148 5.172 5.258 4.912 3.101-.259 9.832-8.354 9.832-8.354.556-.667 1.721-1.212 2.586-1.212l17.134-.003c.866 0 2.03.545 2.585 1.212 0 0 6.732 8.095 9.838 8.354 3.106.26 4.758-1.812 5.255-4.912-.001 0 2.858-12.667-4.225-24.302zm-28.457 9.472H16.7v3.541s-.778.594-1.982.579c-1.202-.018-1.746-.648-1.746-.648v-3.471h-3.47s-.433-.444-.549-1.613c-.114-1.169.479-2.114.479-2.114h3.675v-3.674s.756-.405 1.843-.374a4.86 4.86 0 011.885.443l-.015 3.604h3.47s.606.778.656 1.718c.05.941-.572 2.009-.572 2.009zm16.852 4.036a2.904 2.904 0 01-2.906-2.908 2.902 2.902 0 012.906-2.908 2.909 2.909 0 110 5.816zm0-8.001a2.903 2.903 0 01-2.906-2.907 2.902 2.902 0 012.906-2.908 2.909 2.909 0 012.909 2.908 2.91 2.91 0 01-2.909 2.907zm7.242 4.295a2.903 2.903 0 01-2.906-2.908 2.903 2.903 0 012.906-2.908 2.91 2.91 0 012.909 2.908 2.91 2.91 0 01-2.909 2.908z" />
          </svg>
          <span> Play</span>

        </Link>
      </div>
    </div>
  );
};
