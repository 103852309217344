import React from "react";
import Link from "next/link";
import { ThumbPostPreviewSimple } from "./ThumbPostPreviewSimple";
import { Scrollbars } from "react-custom-scrollbars";
import { removeTags } from "../util/removeTags";

export const PostPreview = ({ data }) => {
  return (
    <div
      className="flex-basis-0 flex mb-4 md:mb-0 block flex-col md:flex-row items-center sm:items-start sm:flex"
      itemScope="itemscope"
      itemType="http://schema.org/Article"
    >
      <div className="relative flex-shrink-0 overflow-hidden md:w-5/12 mr-4">
        <div className="w-52 h-52 md:h-auto md:w-auto">
          <ThumbPostPreviewSimple data={data} />
        </div>
      </div>
      <div className="flex flex-col items-center mt-2 md:mt-0 sm:items-start">


        <Scrollbars
          className="w-full text-muted text-sm mb-2 text-center sm:text-left"
          universal={true}
          autoHeight
          autoHeightMin={0}
          autoHeightMax={82}>
          <p itemProp="description">
            {removeTags(data.excerpt).substring(0, 120) + "..."}
          </p>
        </Scrollbars>
      </div>
    </div>
  );
};
