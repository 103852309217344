import React from "react";
import useAdsByGoogle from "./useAdsByGoogle"

export default function FrontPageContentBanner() {
  useAdsByGoogle();
  return (
    <ins className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client="ca-pub-4646414058040259"
      data-ad-slot="5847741759"
      data-ad-format="auto"
      data-full-width-responsive="true" />
  );
}
