import React, { useRef, useState, useEffect } from "react";
import Link from "next/link";
import SwiperCore, { Lazy, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { ThumbPostPreview } from "./ThumbPostPreview";
import { removeTags } from "../util/removeTags";
import { useWindowWidth } from "@react-hook/window-size";

SwiperCore.use([Lazy, Autoplay]);

export const PromoWidget = ({ data }) => {
  const swiperRef = useRef(null);
  // default width for ssr and set with window hook
  const [windowWidth, setWindowWidth] = useState(1425);
  const hookWidth = useWindowWidth();

  const handleResize = () => {
    setWindowWidth(hookWidth);
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="min-w-0 flex-shrink-0 w-full flex my-4">
        <div className="min-w-0 flex w-full relative flex-col overflow-hidden">
          <Swiper
            // direction="vertical"
            ref={swiperRef}
            spaceBetween={0}
            slidesPerView={1}
            slidesPerGroup={1}
            autoplay={{
              delay: 5000
            }}
            loop={true}
            lazy={true}
          >
            {data.posts.map((node, index) => {
              if (
                !node.mabp_screen1_url ||
                !node.featured_image_url ||
                index > 10
              )
                return (
                  <SwiperSlide key={index}>
                    <PromoWidgetCard data={node} />
                  </SwiperSlide>
                );
            })}
          </Swiper>
          <div className="absolute left-0 bottom-0 border-b-4 border-primary w-full flex justify-end z-30">
            <button
              aria-label="prev"
              className="focus:outline-none w-7 h-7 flex items-center justify-center bg-primary"
              onClick={() => swiperRef.current.swiper.slidePrev()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              aria-label="next"
              className="focus:outline-none w-7 h-7 flex items-center justify-center bg-primary"
              onClick={() => swiperRef.current.swiper.slideNext()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="hidden md:grid grid-cols-2 lg:grid-cols-3 w-promo-widget flex-shrink-0">
          {windowWidth > 800 &&
            data.posts.map((node, index) => {
              if (
                !node.images?.find(
                  (size) => 150 === size.width
                ) ||
                index > (windowWidth > 1200 ? 9 : 4) ||
                index < 1
              ) {
                return null;
              }

              return <ThumbPostPreview key={index} data={node} />;
            })}
        </div>
      </div>
    </>
  );
};

export const PromoWidgetCard = ({ data }) => {
  return (
    <div className="relative w-full h-80 md:h-full">
      <Link
        href={data.uri}
        className="absolute left-0 top-0 w-full h-full flex"
      >
        <img
          width="750"
          height="390"
          className="swiper-lazy object-cover max-h-full w-full block"
          data-src={data.mabp_screen1_url || data.featured_image_url}
          alt=""
        />

      </Link>
      <div className="z-20 absolute bottom-0 left-0 p-5 from-dark">
        <Link href="/" className="text-sm bg-primary font-bold px-1 py-0.5">
          Adventure
        </Link>
        <Link href={data.uri} className="block" >

          <span className="text-lg font-bold">{data.title}</span>
          <span className="text-sm w-full block min-w-0 mb-2">
            {removeTags(data.excerpt) + "..."}
          </span>

        </Link>
      </div>
    </div>
  );
};
