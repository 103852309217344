import React, { useRef } from "react";
import Link from "next/link";
import useHover from "@react-hook/hover";
import classNames from "classnames";
import { noImageUrl } from "../config";

export const ThumbPostPreview = ({ data }) => {
  const target = useRef(null);
  const isHovering = useHover(target);
  const image =
    data.images.find(
      (size) => 150 === size.width || 150 === size.height
    ) ||
    data.featured_image_url ||
    noImageUrl;

  return (
    <div
      className="flex-basis-0 relative overflow-hidden"
      ref={target}
      itemScope="itemscope"
      itemType="http://schema.org/Article"
    >
      <Link href={data.uri} itemProp="url" title={data.title} >
        <img
          className={classNames("w-full h-full", {
            "filter brightness-50 scale-150 transform transition-all":
              isHovering,
          })}
          width="150"
          height="150"
          src={image.sourceUrl ? image.sourceUrl : image}
          alt={data.title}
        />

      </Link>
      <div
        className={classNames(
          "absolute bottom-0 left-0 w-full transition-all p-2",
          {
            hidden: !isHovering,
          }
        )}
      >
        {data.categories.map((node, index) => {
          return (
            <Link
              href={node.uri}
              key={index}
              className="bg-primary text-xs font-bold inline-flex py-1 px-2 hover:bg-block"
              title={node.name}
              itemProp="url"
            >
              {node.name}
            </Link>
          );
        })}
        <Link
          href={data.uri}
          className="block text-sm font-bold my-1"
          itemProp="url"
          title={data.title}
        >
          {data.title}
        </Link>
      </div>
    </div>
  );
};
