import React from "react";
import Head from "next/head";

function getCanonicalUrl(url) {
  let out = url.replace('https://www.radcade.com', '');
  out = url.replace('https://radcade.com', '');
  out = out.replace(/\/$/, '');
  return `https://radcade.com` + out + `/`;
}


export const Meta = ({ title, description, url, image_url, type }) => {
  let metaImage = image_url;
  let canonicalUrl = getCanonicalUrl(url);

  if (!metaImage || metaImage == "") {
    metaImage = '/wp-content/uploads/2020/06/cropped-radcade11cleaning.png';
  }

  return (
    <Head>
      <meta charSet="UTF-8" />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
      />
      <title>{title}</title>
      <meta name="robots" content="max-image-preview:large" />
      <meta name="description" content={description} />
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href="https://radcade.com/wp-content/uploads/2020/06/radcade11cleaning_2_300x200.png"
      />
      <link
        rel="icon"
        href="https://radcade.com/wp-content/uploads/2020/07/cropped-radcade-512square-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        href="https://radcade.com/wp-content/uploads/2020/07/cropped-radcade-512square-192x192.png"
        sizes="192x192"
      />
      <link
        rel="apple-touch-icon"
        href="https://radcade.com/wp-content/uploads/2020/07/cropped-radcade-512square-180x180.png"
      />
      <meta
        name="msapplication-TileImage"
        content="https://radcade.com/wp-content/uploads/2020/07/cropped-radcade-512square-270x270.png"
      />
      <meta
        property="og:image"
        content={metaImage}
      />
      <meta property="og:image:width" content="4096" />
      <meta property="og:image:height" content="2701" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="RadCade" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@TheRadCade" />
      <meta name="twitter:creator" content="@TheRadCade" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description.substring(0, 200)} />
      <meta
        name="twitter:image"
        content={metaImage}
      />

      <meta name="twitter:image:width" content="4096" />
      <meta name="twitter:image:height" content="2701" />
      <link rel="canonical" href={canonicalUrl} />
      {type === "index" && (
        <>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebSite",
              url: "https://radcade.com/",
              name: "RadCade",
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              url: "https://radcade.com/",
              name: "RadCade",
              logo: "https://radcade.com/wp-content/uploads/2020/07/cropped-radcade-512square.png",
            })}
          </script>
        </>
      )}
    </Head>
  );
};
